import { IDTOAuthenLoginRequest } from "controllers/Authen/interface";
import { initialApi, initialState } from "../constant";
import { IAuthenForm, IAuthenState } from "./authen.interface";

export const initialAuthenState: IAuthenState = {
  ...initialState,
  isAuthen: false,
  actionBy: "",
  displayName: "",
  agencyName: "",
  menuDefault: "",
  token: "",
  audience: "",
  issuer: "",
  issueDateTime: "",
  expireIn: null,
  expireDateTime: "",
  menuList: [],
  sessionRefCode: "",
  positionName: "",
  currentFyNo: "",
  checkuserinfo: {},
  // checkuserpass: null,
  // checkuserfetched: false,
};

export const initialAuthenForm: IAuthenForm = {
  username: "",
  password: "",
};
