import { IDTOAuthenLoginResponse } from "controllers/Authen/interface";
import { TError } from "shared/modules/type";

export enum EAuthenAction {
  AUTHEN = "AUTHEN",
  NOT_AUTHEN = "NOT_AUTHEN",
  AUTHEN_LOGIN = "AUTHNE_LOGIN",
  AUTHEN_LOGOUT = "AUTHEN_LOGOUT",
  CHECK_USER_INFO_REQUEST = "CHECK_USER_INFO_REQUEST",
  CHECK_USER_INFO_SUCCESS = "CHECK_USER_INFO_SUCCESS",
  CHECK_USER_INFO_FAILED = "CHECK_USER_INFO_FAILED",
  // CHECK_USER_INFO_FETCHED = "CHECK_USER_INFO_FETCHED",
}

interface IAuthen {
  type: EAuthenAction.AUTHEN;
  data: any;
}

interface INotAuthen {
  type: EAuthenAction.NOT_AUTHEN;
}

// login
interface IAuthenLogin {
  type: EAuthenAction.AUTHEN_LOGIN;
  data: IDTOAuthenLoginResponse;
}

// logout
interface IAuthenLogout {
  type: EAuthenAction.AUTHEN_LOGOUT;
}

// register
interface ICheckUserInfoRequest {
  type: EAuthenAction.CHECK_USER_INFO_REQUEST;
}

interface ICheckUserInfoSuccess {
  type: EAuthenAction.CHECK_USER_INFO_SUCCESS;
  data: any;
}

interface ICheckUserInfoFailed {
  type: EAuthenAction.CHECK_USER_INFO_FAILED;
  error: TError;
}
// interface ICheckUserInfoFetched {
//   type: EAuthenAction.CHECK_USER_INFO_FETCHED;
//   error: TError;
// }

export type TAuthenTypes =
  | IAuthen
  | INotAuthen
  | IAuthenLogin
  | IAuthenLogout
  | ICheckUserInfoRequest
  | ICheckUserInfoSuccess
  | ICheckUserInfoFailed;
// | ICheckUserInfoFetched
