import axios from "axios";
import env from "shared/config/env";

const TIME_OUT = 180_000;

export default axios.create({
  baseURL: env.baseUrl,
  timeout: TIME_OUT,
  headers: {
    "Content-Type": "application/json;utf-8",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});
