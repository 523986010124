import { IDTOAuthenLoginResponse } from "controllers/Authen/interface";
import moment from "moment";
import version from "shared/config/version";
import { initialApi } from "shared/modules/constant";
import { ELocalStorage, TError } from "shared/modules/type";
import axios from "shared/utils/axios";
import { EAuthenAction, TAuthenTypes } from "./authen.type";

export const authen = (cb?: Function): TAuthenTypes => {
  if (cb) cb();
  const user = JSON.parse(localStorage.getItem(ELocalStorage.USER) || "");
  const data: any = {
    ...user,
    menuList: JSON.parse(localStorage.getItem(ELocalStorage.MENU) || ""),
    // actionBy: localStorage.getItem(ELocalStorage.ACTION_BY),
  };
  return {
    type: EAuthenAction.AUTHEN,
    data: data,
  };
};

export const notAuthen = (cb?: Function): TAuthenTypes => {
  if (cb) cb();
  return {
    type: EAuthenAction.NOT_AUTHEN,
  };
};

export const verifyToken = (cb?: Function): TAuthenTypes => {
  if (cb) cb();
  const isAuthen = !!localStorage.getItem(ELocalStorage.TOKEN) || false;
  if (isAuthen) return authen();
  else return notAuthen();
};

// login
export const authenLogin = (
  res: IDTOAuthenLoginResponse,
  cb?: Function
): TAuthenTypes => {
  if (cb) cb(res);
  localStorage.setItem(ELocalStorage.TOKEN, res.token);
  localStorage.setItem(ELocalStorage.MENU, JSON.stringify(res.menuList));
  // localStorage.setItem(ELocalStorage.ACTION_BY, res.actionBy);
  localStorage.setItem(ELocalStorage.VERSION, version.version);
  /**
   * user info
   */
  var fres: any = { ...res };
  delete fres.menuList;
  localStorage.setItem(ELocalStorage.USER, JSON.stringify(fres));
  return {
    type: EAuthenAction.AUTHEN_LOGIN,
    data: res,
  };
};

// logout
export const authenLogout = (cb?: Function): TAuthenTypes => {
  if (cb) cb();
  localStorage.clear();
  return {
    type: EAuthenAction.AUTHEN_LOGOUT,
  };
};

// register
export const checkUserInfo = (data: any, cb?: Function) => {
  return async (dispatch: (arg0: TAuthenTypes) => void) => {
    dispatch({ type: EAuthenAction.CHECK_USER_INFO_REQUEST });
    const body = { idCardNumber: data.idCardNumber };
    try {
      const res = await axios.post("/register/checkUserInfo", {
        ...initialApi,
        ...body,
      });
      dispatch(checkUserInfoSuccess(res, cb));
    } catch (error) {
      dispatch(checkUserInfoFailed(error, cb));
    }
  };
};

const checkUserInfoSuccess = (res: any, cb?: Function): TAuthenTypes => {
  if (cb) cb(true, "");
  return {
    type: EAuthenAction.CHECK_USER_INFO_SUCCESS,
    data: res,
  };
};

const checkUserInfoFailed = (error: any, cb?: Function): TAuthenTypes => {
  if (cb) cb(false, error.response.data.responseMessage);
  return {
    type: EAuthenAction.CHECK_USER_INFO_FAILED,
    error,
  };
};
